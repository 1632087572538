import ConfigManager from '@/config/ConfigManager.js'
import BzbsApi from '@/core/Http/BzbsApi.js'
import Account from '@/helper/AccountHelper.js'
import { map } from 'rxjs/operators'
import dayjs from 'dayjs'
import 'dayjs/locale/en'
dayjs.locale('en')
export default {
  apiGetProject() {
    var url =
      ConfigManager.getAnnouncementAPI() +
      '/api/v1.0/management/get_all_project'
    return BzbsApi.connectBzbsGet(url).pipe(
      map(res => {
        return res.data
      }),
    )
  },

  apiGetListProject() {
    var url =
      ConfigManager.getAnnouncementAPI() +
      '/api/v1.0/management/get_list_project'
    return BzbsApi.connectBzbsGet(url).pipe(
      map(res => {
        return res.data
      }),
    )
  },

  apiGetStatus() {
    var url =
      ConfigManager.getAnnouncementAPI() + '/api/v1.0/management/get_all_status'
    return BzbsApi.connectBzbsGet(url).pipe(
      map(res => {
        return res.data
      }),
    )
  },

  apiGetPostType() {
    var url =
      ConfigManager.getAnnouncementAPI() +
      '/api/v1.0/management/get_all_post_type'
    return BzbsApi.connectBzbsGet(url).pipe(
      map(res => {
        return res.data
      }),
    )
  },

  apiGetRoleType() {
    var url =
      ConfigManager.getAnnouncementAPI() +
      '/api/v1.0/management/get_all_role_type'
    return BzbsApi.connectBzbsGet(url).pipe(
      map(res => {
        return res.data
      }),
    )
  },

  apiGetAnnouncement(params) {
    var url =
      ConfigManager.getAnnouncementAPI() +
      '/api/v1.0/management/get_list_announcement'
    return BzbsApi.connectBzbsGet(url, params)
  },

  apiGetById(data) {
    var url =
      ConfigManager.getAnnouncementAPI() +
      '/api/v1.0/management/get_announcement_by_id?Id=' +
      data
    return BzbsApi.connectBzbsGet(url).pipe(
      map(res => {
        return res.data
      }),
    )
  },

  apiPostDuplicate(data) {
    const token = Account.getToken()
    var url =
      ConfigManager.getAnnouncementAPI() +
      '/api/v1.0/management/copy_announcement_by_id'
    return BzbsApi.connectBzbsPost(url, token, data)
  },

  apiPostHide(data) {
    const token = Account.getToken()
    var url =
      ConfigManager.getAnnouncementAPI() +
      '/api/v1.0/management/hide_announcement_by_id'
    return BzbsApi.connectBzbsPost(url, token, data)
  },

  async apiPostUpsertAnnouncement(params) {
    const token = Account.getToken()
    var start_date = params.start_datetime
      ? dayjs(params.start_datetime).format('YYYY-MM-DDTHH:mm:ss')
      : ''

    let show_to_project = ''
    if (params.show_to_project == 'All') {
      show_to_project = ''
    } else {
      show_to_project = params.show_to_project
    }
    var data = {
      id: params.id,
      title: params.title,
      content: params.content,
      is_required_accept: params.is_required_accept,
      is_active: params.is_active,
      start_datetime: start_date,
      end_datetime: dayjs(params.end_datetime).format('YYYY-MM-DDTHH:mm:ss'),
      show_to_project: show_to_project,
      post_type: params.post_type,
      show_to_roles: params.show_to_roles,
    }
    var url =
      ConfigManager.getAnnouncementAPI() +
      '/api/v1.0/management/upsert_announcement'
    return BzbsApi.connectBzbsPost(url, token, data)
  },

  apiPostUpsertProject(data) {
    const token = Account.getToken()
    var url =
      ConfigManager.getAnnouncementAPI() +
      '/api/v1.0/management/upsert_project'
    return BzbsApi.connectBzbsPost(url, token, data)
  },
}

export default {
  columnsProject() {
    let column = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 180,
      },
      {
        title: 'Display',
        dataIndex: 'display',
        key: 'display',
        width: 180,
      },
      {
        title: 'Client ID',
        dataIndex: 'client_id',
        key: 'client_id',
        width: 220,
      },
      {
        title: 'Status',
        dataIndex: 'is_active',
        key: 'is_active',
        width: 100,
        align: 'center',
      },
      {
        dataIndex: 'action',
        key: 'action',
        width: 60,
        align: 'center',
      },
    ]

    return column
  },
}

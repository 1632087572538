<template>
  <div class="page-404 py-4 text-center">
    <div class="image-404">
      <img :src="image" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
  data() {
    return {
      image: require('@/assets/imgs/404.svg'),
    }
  },
  created() {
    this.handleLoading(false)
  },
  methods: {
    handleLoading(value) {
      this.$store.commit('setLoading', value)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/_mixin.scss';
.image-404 {
  img {
    max-width: 800px;
    margin: 100px;
    display: inline-flex;
  }
}
</style>

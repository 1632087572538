<template>
  <div class="tab-horizontal">
    <a-tabs
      :activeKey="activeKey"
      :tab-position="mode"
      class="horizon"
      @change="$emit('tabValue', $event)"
    >
      <a-tab-pane
        v-for="item in tabList"
        :key="`${item.name}`"
        :tab="`${item.display}`"
      >
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue'
export default defineComponent({
  props: {
    activeKey: ref(),
    tabList: Array,
  },
  setup() {
    const mode = ref('top')
    return {
      mode,
    }
  },
  created() {},
})
</script>
<style lang="scss">
.tab-horizontal {
  .horizon .ant-tabs-nav {
    margin: 0;
  }
  .ant-tabs-tab {
    padding: 12px 16px;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }
}
</style>

<template>
  <div class="row p-0 mt-1">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
      <span class="desc-radio">{{ desc }}</span>
      <h5 class="mb-2 bold title-radio">{{ title }}</h5>
    </div>
    <div v-if="isVertical" class="row p-0 m-0">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
        <a-radio-group v-model:value="selectRole" :disabled="disabled">
          <a-radio
            v-for="option of options"
            :key="option"
            class="radio-style"
            :value="option.value"
            @change="$emit('userVisible', option.value)"
            >{{ option.name }}</a-radio
          >
        </a-radio-group>
      </div>
    </div>
    <a-radio-group
      v-else
      v-model:value="requireValue"
      :disabled="disabled"
      @change="$emit('requiredAction', requireValue)"
    >
      <div class="row p-0 m-0">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 radio-wrap">
          <a-radio class="radio-style" :value="false">No action</a-radio>
        </div>
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 radio-wrap">
          <a-radio class="radio-style" :value="true"
            >Need, Accept checkbox from user</a-radio
          >
        </div>
      </div>
    </a-radio-group>
  </div>
</template>
<script>
import { defineComponent, onUpdated, ref, toRef } from 'vue'

export default defineComponent({
  props: {
    title: String,
    desc: String,
    isVertical: Boolean,
    disabled: Boolean,
    options: Array,
    roleSelect: String,
    required: Boolean,
  },
  setup(props) {
    var selectRole = toRef(props, 'roleSelect')
    const requireValue = ref(props.required)
    onUpdated(() => {
      requireValue.value = props.required
    })
    return {
      selectRole,
      requireValue,
    }
  },
})
</script>
<style lang="scss" scoped>
.radio-wrap {
  white-space: nowrap;
}
.title-radio {
  position: relative;
}
.desc-radio {
  position: absolute;
  margin-left: 95px;
}
</style>

<template>
  <div class="layout-auth page-layout">
    <div
      class="layout-background"
      :style="{ 'background-image': 'url(' + banner + ')' }"
    ></div>
    <div class="layout-content py-5">
      <div class="logo text-center">
        <img :src="img_logo" :alt="img_logo" />
      </div>
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { config } from '@/config/bzbsconfig.js'
export default {
  name: 'AuthLayout',
  components: {},
  data() {
    return {
      logo: require('@/assets/icons/logo-new.png'),
      img_logo:
        config.bzbBlobUrl + '/themebackoffice/assets/img/logo-icon.svg',
      banner: require('@/assets/background/bg_login.png'),
    }
  },
}
</script>
<style lang="scss">
.layout-auth {
  min-height: 100vh;
  display: flex;
  display: -webkit-flex;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  .layout-background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  .layout-content {
    width: 450px;
    max-width: 100%;
    z-index: 1;
    padding: 0 15px;
    margin: auto;
    .logo {
      margin-bottom: -40px;
      img {
        max-width: 102px;
        display: initial;
        @media (max-width: 576px) {
          max-width: 80px;
        }
      }
      .title {
        color: var(--color-white);
        font-size: 16px;
        text-transform: uppercase;
      }
    }
    .content-box {
      background-color: var(--color-white);
      border-radius: 5px;
      padding: 55px 40px 10px;
      box-shadow: 0px 7px 21px rgba(#000, 0.16);
      @media (max-width: 576px) {
        padding: 55px 30px 10px;
      }
      .content-title {
        &--line {
          padding-bottom: 12px;
        }
        h3 {
          color: var(--primary-gold-bzb-primary-bzb);
          font-size: calc(16 / 14) rem;
        }
      }
      .ant-form-item-control {
        line-height: 0;
      }
    }
  }
  .form-button {
    button {
      width: 100%;
      border-radius: 30px;
      padding: 9px;
      height: auto;
      margin-bottom: 12px;
      color: var(--color-white);

      &.ant-btn-facebook,
      &.ant-btn-google,
      &.ant-btn-ms {
        display: flex;
        display: -webkit-flex;
        justify-content: center;
        -webkit-justify-content: center;
        align-items: center;
        -webkit-align-items: center;
        i {
          font-size: 22px;
          margin-right: 10px;
        }
      }
    }
    // @include btn-type(facebook);
    // @include btn-type(google);
    // @include btn-type(ms);
  }
}
</style>

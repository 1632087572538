<template>
  <div class="tab-vertical p-0">
    <div
      v-if="windowWidth > 767"
      class="col-md-12 col-12 p-3 short-text text-status"
    >
      Announcement Status
    </div>
    <a-tabs
      :activeKey="activeKey"
      :tab-position="modeStatus"
      @change="$emit('tabValue', $event)"
      class="vertical"
    >
      <a-tab-pane
        v-for="item in tabList"
        :key="`${item.name}`"
        :tab="`${item.name}`"
      >
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted, onUnmounted } from 'vue'
export default defineComponent({
  props: {
    activeKey: ref(),
    tabList: Array,
  },
  setup() {
    const modeStatus = ref('left')
    const windowWidth = ref(window.innerWidth)

    const handleResize = () => {
      windowWidth.value = window.innerWidth
      modeStatus.value = windowWidth.value < 768 ? 'top' : 'left'
    }
    onMounted(() => {
      handleResize()
      window.addEventListener('resize', handleResize)
    })
    onUnmounted(() => {
      window.removeEventListener('resize', handleResize)
    })
    return {
      modeStatus,
      windowWidth,
      handleResize,
    }
  },
})
</script>
<style lang="scss">
.tab-vertical {
  overflow: hidden;
  height: 100%;
  .text-status {
    font-size: 12px;
    background: var(--grey-grey-9);
    color: var(--text-light-text-caption);
  }
  .short-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.line-h {
      line-height: 1.5;
    }
  }
  .ant-tabs-top > .ant-tabs-nav {
    margin: 0;
  }
  .ant-tabs.ant-tabs-left.vertical {
    height: 100%;
  }
  .ant-tabs-nav {
    width: 100%;
  }
  .ant-tabs-tab {
    padding: 12px 16px;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }
}
</style>

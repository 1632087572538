// lbl.profile
export const profile = {
    firstname: {
        name: 'Firstname',
        placeholder: 'Firstname',
        required: 'Please enter firstname',
        too_short: '',
        invalid: '',
    },
    lastname: {
        name: 'Lastname',
        placeholder: 'Lastname',
        required: 'Please enter lastname',
        too_short: '',
        invalid: '',
    },
    contactNumber: {
        name: 'Contact number',
        placeholder: 'Contact number',
        required: 'Contact number',
        too_short: 'Contact number must be {0} digits',
        invalid: 'Incorrect contact number format',
    },
    email: {
        name: 'Email',
        placeholder: 'Email',
        required: 'Please enter email address',
        too_short: '',
        invalid: 'Incorrect email format',
    },
    idCard: {
        name: 'ID card',
        placeholder: 'ID card',
        required: 'Pleae enter ID card',
        too_short: 'Pleae enter ID card 13 digits',
        invalid: 'ID card invalid',
    },
    password: {
        name: 'Password',
        placeholder: 'Password',
        required: 'Please enter passwrod',
        too_short: '',
        invalid: '',
    },
    gender: {
        name: 'Gender',
        placeholder: 'Please select',
        required: 'Please select gender',
        too_short: '',
        invalid: '',
    },
    birthdate: {
        name: 'Birthdate',
        placeholder: '',
        required: 'Please select birthdate',
        too_short: '',
        invalid: 'Birthdate invalid',
        date: {
            name: '',
            placeholder: 'Day',
            required: ' '
        },
        month: {
            name: '',
            placeholder: 'Month',
            required: ' '
        },
        year: {
            name: '',
            placeholder: 'Year',
            required: ' '
        },
    },
    zipcode: {
        name: 'Zipcode',
        placeholder: 'Zipcode',
        required: 'Please enter zipcode',
        too_short: 'Zipcode must be {0} digits',
        invalid: 'Invalid zipcode',
    },
    subdistrict: {
        name: 'Sub district',
        placeholder: 'Please selece sub-district',
        required: 'Please selece sub-district',
        too_short: '',
        invalid: '',
    },
    district: {
        name: 'District',
        placeholder: 'Please selece district',
        required: 'Please selece district',
        too_short: '',
        invalid: '',
    },
    province: {
        name: 'Province',
        placeholder: 'Please selece province',
        required: 'Please selece province',
        too_short: '',
        invalid: '',
    },

    male: "Male",
    female: "Female",
    notspecified: "Notspecified",
};

import CacheHelper from '@/helper/CacheHelper.js'
import ConfigManager from '@/config/ConfigManager.js'

import * as Msal from '@azure/msal-browser'
import { config } from '@/config/bzbsconfig.js'
const myMSAL = new Msal.PublicClientApplication(config.msalConfig)

var Cache = new CacheHelper(ConfigManager.getCacheType())
import _ from 'lodash'
import { h } from 'vue'
export default {
  methods: {
    errorApi: function (objerror) {
      var error_message = 'Network connection error!'
      var texterror = []
      if (typeof objerror === 'string') {
        error_message = objerror
      }
      if (_.has(objerror, 'response.data.error')) {
        if (objerror.response.data.error !== null) {
          for (var i = 0; i < objerror.response.data.error.length; i++) {
            texterror.push(h('p', objerror.response.data.error[i].message))
          }
          error_message = texterror
        }
      }
      if (objerror.response && objerror.response.status == 401) {
        let testRequest = config.tokenRequest
        testRequest.account = ' '
        myMSAL
          .acquireTokenSilent(testRequest)
          .then(res => {
            Cache.setCache('msToken', res.accessToken)
            this.$router.go()
            this.handleLoading(false)
          })
          .catch(err => {
            console.log(err)
            Cache.removeCache('msToken')
            localStorage.removeItem('bzbsFeatures')
            this.$router.push({ name: 'login' })
            this.handleLoading(false)
          })
        // Cache.removeCache('msToken')
        // myMSAL
        //   .ssoSilent(config.loginRequest)
        //   .then(res => {
        //     Cache.setCache('msToken', res.accessToken)
        //     var jsonAccount = JSON.stringify({
        //       name: res.account.name
        //         ? res.account.name
        //         : res.account.username.split('@')[0],
        //       shortedname: res.account.name.includes(' ')
        //         ? res.account.name.split(' ')[0][0] +
        //           ' ' +
        //           res.account.name.split(' ')[1][0]
        //         : res.account.name[0],
        //       email: res.account.username,
        //     })
        //     Cache.setCache('account', jsonAccount)
        //     this.$router.go()
        //   })
        //   .catch(() => {
        //     this.$store.commit('setLoading', false)
        //     this.$error({
        //       content: error_message,
        //       centered: true,
        //       okType: 'default',
        //       autoFocusButton: null,
        //       onOk: () => {
        //         console.log('OK')
        //         this.$router.push({
        //           name: 'logout',
        //         })
        //       },
        //     })
        //   })
      } else {
        this.$store.commit('setLoading', false)
        this.$error({
          // title: texterror,
          content: error_message,
          okType: 'default',
          centered: true,
          autoFocusButton: null,
          onOk: () => {
            this.$store.commit('setLoading', false)
            if (objerror.response && objerror.response.status == 401) {
              this.$router.push({
                name: 'logout',
              })
              // if (objerror.response.data.error[0].code == '401001' || objerror.response.data.error[0].code == '401002') {
              //   this.$router.push({
              //     name: 'logout',
              //   })
              // }
              // if (objerror.response.data.error[0].code == '401003') {
              //   this.$router.push({
              //     name: 'select_application',
              //   })
              // }
            } else {
              this.$router.go()
            }
          },
        })
      }
    },
    errorResponse(objerror, isPopup, router) {
      if (
        objerror.errors == '401' &&
        objerror.message == 'A JWT specified in the request header is expired.'
      ) {
        if (Cache.getCache('popupExpired')) {
          return
        }
        router = {
          name: 'login',
        }
        Cache.removeCache('msToken')
        localStorage.removeItem('bzbsFeatures')
        Cache.setCache('popupExpired', true)
      }
      var error_message = ''
      switch (objerror.errors) {
        case '401':
          error_message = objerror.message
          break
        case '412':
          error_message = 'Token invalid'
          break
        default:
          error_message = 'Network connection error!'
      }
      if (isPopup) {
        if (router) {
          this.$error({
            title: error_message,
            // content: error_message,
            okType: 'default',
            centered: true,
            autoFocusButton: null,
            onOk: () => {
              if (Cache.getCache('popupExpired')) {
                Cache.removeCache('popupExpired')
              }
              this.$router.push(router)
            },
          })
        } else {
          this.$error({
            title: error_message,
            // content: error_message,
            okType: 'default',
            centered: true,
            autoFocusButton: null,
            onOk: () => {
              this.$router.push({
                name: 'login',
              })
            },
          })
        }
      } else {
        this.$message.error(error_message)
      }
    },
  },
}

import _ from 'lodash'
import { h } from 'vue'
import { Modal } from 'ant-design-vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import * as Msal from '@azure/msal-browser'
import { config } from '@/config/bzbsconfig.js'
const myMSAL = new Msal.PublicClientApplication(config.msalConfig)
import CacheHelper from '@/helper/CacheHelper.js'
import ConfigManager from '@/config/ConfigManager.js'
var Cache = new CacheHelper(ConfigManager.getCacheType())
export default function useError() {
  const router = useRouter()
  const store = useStore()
  function errorApi(objerror) {
    store.commit('setLoading', false)
    var error_message = 'Network connection error!'
    var texterror = []
    if (typeof objerror === 'string') {
      error_message = objerror
    }
    if (_.has(objerror, 'response.data.error')) {
      if (objerror.data.error !== null) {
        for (var i = 0; i < objerror.response.data.error.length; i++) {
          texterror.push(h('p', objerror.response.data.error[i].message))
        }
        error_message = texterror
      }
    }
    if (objerror && objerror.status == 401) {
      let testRequest = config.tokenRequest
      testRequest.account = ' '
      myMSAL
        .acquireTokenSilent(testRequest)
        .then(res => {
          Cache.setCache('msToken', res.accessToken)
          router.go()
          store.commit('setLoading', false)
        })
        .catch(err => {
          console.log(err)
          Cache.removeCache('msToken')
          localStorage.removeItem('bzbsFeatures')
          router.push({ name: 'logout' })
          store.commit('setLoading', false)
        })
    } else {
      store.commit('setLoading', false)
      Modal.error({
        title: 'Error',
        centered: true,
        content: error_message,
        onOk: () => {
          store.commit('setLoading', false)
        },
      })
    }
  }
  function successApi(response) {
    var error_message = 'Network connection error!'
    var texterror = []
    if (typeof objerror === 'string') {
      error_message = response
    }
    if (_.has(response, 'response.data.error')) {
      if (response.data.error !== null) {
        for (var i = 0; i < response.response.data.error.length; i++) {
          texterror.push(h('p', response.response.data.error[i].message))
        }
        error_message = texterror
      }
    }
    Modal.success({
      title: 'Error',
      centered: true,
      content: error_message,
      onOk: () => {
        router.push({ name: 'announcement' })
      },
    })
  }
  function reToken() {
    return new Promise(resolve => {
      let testRequest = config.tokenRequest
      testRequest.account = ' '
      myMSAL
        .acquireTokenSilent(testRequest)
        .then(res => {
          Cache.setCache('msToken', res.accessToken)
          store.commit('setLoading', false)
          resolve(true)
        })
        .catch(err => {
          console.log(err)
          Cache.removeCache('msToken')
          localStorage.removeItem('bzbsFeatures')
          router.push({ name: 'logout' })
          store.commit('setLoading', false)
          resolve(false)
        })      
    })
  }
  return {
    errorApi,
    successApi,
    reToken,
  }
}

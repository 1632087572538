// import { register } from "@/module/Register/locale/register.th.js";

import { profile } from "@/module/Profile/locale/profile.th.js";

export const th = {
    default: {
        search: 'ค้นหา',
        confirm: 'ยืนยัน',
        ok: 'ตกลง',
        cancel: 'ยกเลิก',
        close: 'ปิด',
    },

    profile: profile,

    date: {
        days: 'วัน',
        months: 'เดือน',
        years: 'ปี',
        minutes: 'นาที',
        minutes_short: 'น.',
        hours: 'ชั่วโมง',
        hours_short: 'ช.ม.',
        date_flase: 'กรุณาระบุวันเกิด',
        month_full: ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'],
        month_short: ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'],
        month_full_arr: [{ strvalue: "01", strtext: 'มกราคม' },
        { strvalue: '02', strtext: 'กุมภาพันธ์' },
        { strvalue: '03', strtext: 'มีนาคม' },
        { strvalue: '04', strtext: 'เมษายน' },
        { strvalue: '05', strtext: 'พฤษภาคม' },
        { strvalue: '06', strtext: 'มิถุนายน' },
        { strvalue: '07', strtext: 'กรกฎาคม' },
        { strvalue: '08', strtext: 'สิงหาคม' },
        { strvalue: '09', strtext: 'กันยายน' },
        { strvalue: '10', strtext: 'ตุลาคม' },
        { strvalue: '11', strtext: 'พฤศจิกายน' },
        { strvalue: '12', strtext: 'ธันวาคม' }]
    },
    confirm_redeem: {
        confirm: "ยืนยัน",
        cancel: "ยกเลิก",

        headline: 'ยืนยัน',
        default: 'ยืนยันการรับสิทธิ์',
        defualt_have_time: 'รหัสจะมีอายุ {0} หลังการยืนยัน',
        delivery: 'กรุณากรอกที่อยู่จัดส่ง',
        free: 'รหัสจะมีอายุ {0} หลังการยืนยัน',
        free_no_timer: 'ยืนยันการรับสิทธิ์',
        free_delivery: 'กรุณากรอกที่อยู่จัดส่ง',
        draw: 'ยืนยันเพื่อเข้าร่วมสนุก <br/> ประกาศผู้ชนะหลังวันที่ {0}',
        draw_delivery: 'กรุณากรอกที่อยู่จัดส่ง',
        deal: 'รหัสจะมีอายุ {0} หลังการยืนยัน',
        deal_no_timer: 'ยืนยันการรับสิทธิ์',
        deal_delivery: 'กรุณากรอกที่อยู่จัดส่ง',
        buy: 'ยืนยันการซื้อ',
        buy_delivery: 'กรุณากรอกที่อยู่จัดส่ง',
        interface: 'ยืนยันเพื่อเข้าสู่เว็บไซต์ที่ร่วมรายการ',
    },
    redeem_button: {
        default: 'กดรับสิทธิ์',
        none: '',
        soldOut: 'ขออภัย มีผู้ใช้สิทธิ์เต็มจำนวนแล้ว',
        soldOutBuy: 'ขออภัย มีผู้ใช้สิทธิ์เต็มจำนวนแล้ว',
        maxPerPerson: 'ขออภัย คุณได้แลกรับสิทธิ์นี้แล้ว',
        redeemCoolDown: 'ขออภัย คุณได้รับ Campaign นี้ไปแล้ว กรุณารอกดรับใหม่อีกครั้งภายหลัง',
        nextDate: 'รับสิทธิ์ครั้งต่อไปได้<br/>ตั้งแต่ : ',
        exceedBuyPointLimitPerday: '',
        conditionIsInvalid: 'คุณต้องทำตามเงื่อนไขที่ระบุไว้ใน Campaign นี้ก่อนจึงสามารถกดรับได้ กรุณาอ่านเพิ่มเติมในรายละเอียด',
        campaignExpired: 'สินค้านี้หมดอายุแล้ว',
        campaignExpiredBuy: 'สินค้านี้หมดอายุแล้ว',
        campaignNotAvailable: 'สิทธิพิเศษนี้ไม่สามารถรับได้ในตอนนี้',
        campaignNotAvailableBuy: 'สินค้านี้ไม่สามารถรับได้ในตอนนี้',
        notEnoughPoint: 'คะแนนของคุณไม่เพียงพอที่จะแลกรับสิทธิ์',
        notSupport: 'ไม่รองรับแคมเปญประเภทนี้'
    },
    purchase: {
        show_code: 'กดเพื่อดูรหัส',
        code_expired: 'รหัสหมดอายุ',

        delivery: 'สินค้าจะถูกจัดส่งตามเงื่อนไขของแคมเปญ',
        shipped: 'สินค้าได้ถูกจัดส่งเรียบร้อยแล้ว',
        parcel_no: 'พัสดุเลขที่',

        not_draw1: 'รางวัลนี้ยังไม่ได้ประกาศรายชื่อผู้โชคดี',
        not_draw2: 'กรุณากลับมาตรวจสอบใหม่ภายหลัง',
        draw_winner1: 'ขอแสดงความยินดีด้วยค่ะ',
        draw_winner2: 'คุณเป็นผู้โชคดีได้รับของรางวัล',
        draw_not_win1: 'ขออภัย คุณไม่ใช่ผู้โชคดีได้รับของรางวัลนี้',
        draw_not_win2: 'กรุณาร่วมสนุกกันใหม่ในครั้งหน้า',
    },
    serial: {
        close_btn: 'ปิด',
        default_privilege_message: 'ตามดีไซน์',
        show_code_at_shop: 'กรุณาแสดงรหัสนี้ ให้แก่ร้านค้า',

        delivery: 'สินค้าจะถูกจัดส่งตามเงื่อนไขของแคมเปญ',

        draw: 'ขอบคุณที่ร่วมสนุกกับเรา<br/>กรุณาติดตามประกาศผล หลังจากวันที่ {0}',
        draw_after: 'หลังจากวันที่',

        qr_code: 'คิวอาร์โค้ด',
        barcode: 'บาร์โค้ด',
        code: 'โค้ด',

        copy_code: 'คัดลอก',

        expire_in: 'หมดเวลาใน ',
        code_expired: 'รหัสหมดอายุ',

        redeem_on: 'รับสิทธิ์เมื่อ {0}'
    },
    shipping: {
        headline: 'ที่อยู่จัดส่ง',

        firstname: {
            name: 'ชื่อ',
            placeholder: 'ชื่อ',
            required: 'กรุณากรอกชื่อ',
            too_short: '',
            invalid: '',
        },
        lastname: {
            name: 'นามสกุล',
            placeholder: 'นามสกุล',
            required: 'กรุณากรอกนามสกุล',
            too_short: '',
            invalid: '',
        },
        addressName: {
            name: 'ชื่อที่อยู่',
            placeholder: 'ชื่อที่อยู่',
            required: 'กรุณากรอกชื่อที่อยู่',
            too_short: '',
            invalid: '',
        },
        address:  {
            name: 'ที่อยู่',
            placeholder: 'กรุณากรอกที่อยู่',
            required: 'กรุณากรอกที่อยู่',
            too_short: '',
            invalid: '',
        },
        contactnumber: {
            name: 'เบอร์โทรศัพท์',
            placeholder: 'เบอร์โทรศัพท์',
            required: 'กรุณากรอกเบอร์โทรศัพท์',
            too_short: 'เบอร์โทรศัพท์ต้องมี {0} หลัก',
            invalid: 'เบอร์โทรศัพท์ไม่ถูกต้อง',
        },
        nationalid: {
            name: 'เลขประจำตัวประชาชน',
            placeholder: 'เลขประจำตัวประชาชน',
            required: 'กรุณากรอกเลขประจำตัวประชาชน',
            too_short: 'เลขประจำตัวประชาชนต้องมี {0} หลัก',
            invalid: 'เลขประจำตัวประชาชนไม่ถูกต้อง',
        },
        email: {
            name: 'อีเมล',
            placeholder: 'อีเมล',
            required: 'กรุณากรอกอีเมล',
            too_short: '',
            invalid: 'อีเมลไม่ถูกต้อง',
        },
        zipcode: {
            name: 'รหัสไปรษณีย์',
            placeholder: 'รหัสไปรษณีย์',
            required: 'กรุณากรอกรหัสไปรษณีย์',
            too_short: 'รหัสไปรษณีย์ต้องมี {0} หลัก',
            invalid: 'รหัสไปรษณีย์ไม่ถูกต้อง',
        },
        subdistrict: {
            name: 'แขวง/ตำบล',
            placeholder: 'กรุณาเลือกแขวง/ตำบล',
            required: 'กรุณาเลือกแขวง/ตำบล',
            too_short: '',
            invalid: '',
        },
        district: {
            name: 'เขต/อำเภอ',
            placeholder: 'กรุณาเลือกเขต/อำเภอ',
            required: 'กรุณาเลือกเขต/อำเภอ',
            too_short: '',
            invalid: '',
        },
        province: {
            name: 'จังหวัด',
            placeholder: 'กรุณาเลือกจังหวัด',
            required: 'กรุณาเลือกจังหวัด',
            too_short: '',
            invalid: '',
        },

        isDefaultAddress: 'ตั้งเป็นที่อยู่หลัก',
        defaultAddress: 'ที่อยู่หลัก',
        save_address: 'บันทึกที่อยู่เพื่อใช้รับสิทธิ์ครั้งต่อไป',
        save_address_buy: 'บันทึกที่อยู่เพื่อใช้ในการสั่งซื้อครั้งต่อไป',

        confirm_btn: 'ยืนยัน',
        cancel_btn: 'ยกเลิก',

        alert_headline: 'ที่อยู่จัดส่ง',
        alert_ok_btn: 'ตกลง',
    },
    tax: {
        personType: {
            name: 'ออกในนาม',
            placeholder: '',
            required: 'กรุณาเลือก',
            too_short: '',
            invalid: '',
        },
        title: {
            name: 'คำนำหน้าชื่อ',
            placeholder: '',
            required: 'กรุณาเลือก',
            too_short: '',
            invalid: '',
        },
        companyName: {
            name: 'ชื่อ / บริษัท',
            placeholder: 'ชื่อ / บริษัท',
            required: 'กรุณากรอกข้อมูลให้ครบถ้วน',
            too_short: '',
            invalid: '',
        },
        building: {
            name: 'อาคาร',
            placeholder: 'อาคาร',
            required: '',
            too_short: '',
            invalid: '',
        },
        room: {
            name: 'เลขที่ห้อง',
            placeholder: 'เลขที่ห้อง',
            required: '',
            too_short: '',
            invalid: '',
        },
        floor: {
            name: 'ชั้นที่',
            placeholder: 'ชั้นที่',
            required: '',
            too_short: '',
            invalid: '',
        },
        village: {
            name: 'หมู่บ้าน',
            placeholder: 'หมู่บ้าน',
            required: '',
            too_short: '',
            invalid: '',
        },
        number: {
            name: 'เลขที่',
            placeholder: 'เลขที่',
            required: '',
            too_short: '',
            invalid: '',
        },
        moo: {
            name: 'หมู่ที่',
            placeholder: 'หมู่ที่',
            required: '',
            too_short: '',
            invalid: '',
        },
        soi: {
            name: 'ซอย',
            placeholder: 'ซอย',
            required: '',
            too_short: '',
            invalid: '',
        },
        road: {
            name: 'ถนน',
            placeholder: 'ถนน',
            required: '',
            too_short: '',
            invalid: '',
        },
    },
    main: {
        home_page: "หน้าแรก",
        login: "เข้าสู่ระบบ",
        campaign: "แคมเปญ",
        category: 'หมวดหมู่',
        map: "แผนที่",
        terms: "Terms And Conditions",
        history: 'ประวัติรับสิทธิ์',
        profile: "โปรไฟล์",
        logout: "ออกจากระบบ",
        lang: "ภาษา",
        msgLogout: "ต้องการออกจากระบบ",
        cancel: "ยกเลิก",
    },
    campaign: {
        point: 'คะแนน',
        get_point: 'ได้คะแนน : ',
        use_point: 'ใช้คะแนน : ',
    },
    detail: {
        detail: 'รายละเอียด',
        condition: 'เงื่อนไข',

        point_unit: 'คะแนน',
        get_point: 'ได้คะแนน : ',
        use_point: 'ใช้คะแนน : ',
        available_date: 'แลกได้วันที่',
        expire_date: 'วันหมดอายุ : {0}',

        color: {
            name: 'สี',
            placeholder: 'กรุณาเลือกสี',
            required: 'กรุณาเลือกสี',
        },
        size: {
            name: 'ขนาด',
            placeholder: 'กรุณาเลือกขนาด',
            required: 'กรุณาเลือกขนาด',
        },
    },
    history: {
        point: 'คะแนน',
        get_point: 'ได้คะแนน : ',
        use_point: 'ใช้คะแนน : ',

        parcel_number: 'เลขพัสดุ : ',
    }
};

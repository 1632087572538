<template>
  <div class="topbar-container p-0">
    <div class="flex-container">
      <div class="flex-item-title text-left">
        <Breadcrumb :title="title"></Breadcrumb>
      </div>
      <div class="flex-item-filter text-right">
        <a-input-search
          v-model:value="value"
          placeholder="Find with filter"
          class="input-search default-height"
          @search="$emit('searchValue', $event)"
        >
          <template #enterButton>
            <a-button class="btn-icon"><search-outlined /></a-button>
          </template>
        </a-input-search>
        <a-button class="create-btn ml-4" type="primary" @click="onClickCreate">
          <plus-outlined class="plus-icon" />
          <span class="create-span">Create New Post</span>
        </a-button>
      </div>
    </div>
  </div>
</template>
<script setup>
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb.vue'
import { useRouter } from 'vue-router'

const title = 'Announcement Board'
const router = useRouter()
const onClickCreate = () => {
  router.push({ name: 'createpost' })
}
</script>
<style lang="scss">
.topbar-container {
  .flex-container {
    display: flex;
    flex-direction: row;
  }
  .flex-item-title {
    flex: 50%;
  }
  .flex-item-filter {
    padding: 16px 0;
    flex: 50%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .input-search {
    width: 300px;
    height: 40px;
  }
  .breadtrumb-title {
    position: relative;
  }
  .icon-title {
    width: 22px;
    height: 22px;
    position: absolute;
    margin: -48px 0px -10px -10px;
  }
  .create-span {
    vertical-align: inherit;
  }
  .plus-icon {
    font-size: 18px;
    vertical-align: inherit;
  }

  @media only screen and (max-width: 1060px) {
    .flex-item-title {
      flex: 30%;
    }
    .flex-item-filter {
      flex: 70%;
    }
  }
  @media only screen and (max-width: 890px) {
    .flex-container {
      flex-direction: column;
    }
    .input-search {
      max-width: calc(80% - 120px);
    }
  }
  @media only screen and (max-width: 600px) {
    .input-search .create-btn {
      width: 100%;
    }
    .flex-item-title {
      flex: 100%;
    }
    .flex-item-filter {
      flex: 100%;
    }
  }
}
</style>

import Config from './vueconfig.js'
import CacheHelper from '@/helper/CacheHelper.js'
/**
 * environment
 * @function getAppId
 * @function getAppName
 * @function getBlobUrl
 * api config str
 * @function getCampaignConfig
 * @function getDashboardConfig
 * @function getCategoryConfig
 * @function getHistoryConfig
 * @function getAnnouncementConfig
 * setting
 * @function getCacheType
 * custom
 * @function getCampaignListDevApp
 * @function getCategoryMenuDev
 */
export default {
  //#region normal environment
  getAppId: function () {
    var Cache = new CacheHelper(this.getCacheType())
    return Cache.getCache('app').id
  },
  getAppName: function () {
    var Cache = new CacheHelper(this.getCacheType())
    return Cache.getCache('app')?.name
  },
  getAppLogo: function () {
    var Cache = new CacheHelper(this.getCacheType())
    return Cache.getCache('app')?.logo
  },
  getApiadB2CUrl: function () {
    return Config().adB2CUrl
  },
  getApi: function () {
    return Config().api
  },
  //#endregion normal environment

  //#region config str
  getCampaignConfig: function () {
    return Config().campaignConfig
  },
  getDashboardConfig: function () {
    return Config().dashboardConfig
  },
  getCategoryConfig: function () {
    return Config().categoryConfig
  },
  getHistoryConfig: function () {
    return Config().historyConfig
  },
  //#endregion config str

  //#region setting
  getCacheType: function () {
    return Config().setting.cache
  },
  getAddressType: function () {
    return Config().setting.addressType
  },
  getFirebaseSetup: function () {
    return Config().firebase
  },
  //#endregion setting

  //#region custom
  getCampaignListDevApp: function () {
    return this.getCampaignConfig().campaignListConfig
  },
  getCategoryMenuDev: function () {
    return this.getCategoryConfig().menuCategory
  },
  getHistoryPurchaseList: function () {
    return this.getHistoryConfig().purchase
  },
  //#endregion custom

  //Announcement Management
  getAnnouncementAPI: function () {
    return Config().announcementManagementAPI
  },
}

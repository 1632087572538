import { config } from '@/config/bzbsconfig.js'; // auto build use this
// import { config } from '@/config/bzbsconfig.dev.js';
import { config as configStg} from '@/config/bzbsconfig.stg.js';
// import { config } from '@/config/bzbsconfig.prd.js';

import { campaignConfig, dashboardConfig, categoryConfig, historyConfig } from './configlist.js';
// import { ConfigManager } from './ConfigManager.js';

export default function () {
    let appConfig = null;

    if (config.debug) {
        var runLocalEnvironment = 'dev';
        // console.log("run environment", runLocalEnvironment);

        switch (runLocalEnvironment) {
            case 'dev':
                appConfig = config;
                break;
            case 'stg':
                appConfig = configStg;
                break;
        }
    } else {
        appConfig = config;
    }

    appConfig.campaignConfig = campaignConfig;
    appConfig.dashboardConfig = dashboardConfig;
    appConfig.categoryConfig = categoryConfig;
    appConfig.historyConfig = historyConfig;

    appConfig.setting = {
        cache: 'cookie',
        addressType: 'addressList', // profile, addressList
    };

    return appConfig;
    // install: (app) => {


    //     let configManager = new ConfigManager(appConfig);
    //     app.mixin({
    //         data: function () {
    //             return {
    //                 appConfig: appConfig,
    //                 configManager: configManager,
    //             }
    //         },
    //     });
    // }
}

<template>
  <div class="title-breadcrumbs p-0">
    <a-breadcrumb v-if="databreadcrumbs ? databreadcrumbs.length > 1 : false" :routes="databreadcrumbs">
      <template #itemRender="{ route, routes }">
        <span v-if="routes.indexOf(route) === routes.length - 1">
          {{ route.breadcrumbName }}
        </span>
        <template v-else>
          <template v-if="routes.length > 4">
            <template v-if="routes.indexOf(route) === 0">
              <router-link v-if="route.name" :to="{ name: route.name }">
                {{ route.breadcrumbName }}
              </router-link>
              <span v-else>
                {{ route.breadcrumbName }}
              </span>
            </template>
            <template v-if="routes.indexOf(route) === routes.length - 2">
              <router-link v-if="route.name" :to="{ name: route.name }">
                {{ route.breadcrumbName }}
              </router-link>
              <span v-else>
                {{ route.breadcrumbName }}
              </span>
            </template>
            <span v-if="routes.indexOf(route) === routes.length - 3">
              ...
            </span>
          </template>
          <template v-else>
            <router-link v-if="route.name" :to="{ name: route.name }">
              {{ route.breadcrumbName }}
            </router-link>
            <span v-else>
              {{ route.breadcrumbName }}
            </span>
          </template>
        </template>
      </template>
    </a-breadcrumb>
    <a-page-header :sub-title="description">
      <template v-if="status" #title>
        {{ title }}
        <template v-for="(item, index) in statusobj">
          <a-tag
            v-if="status.id == item.id"
            :key="index"
            :color="item.color == 'default' ? null : item.color"
            class="tag-status"
          >
            <a-badge
              :color="item.color == 'default' ? 'lightgrey' : item.color"
              :text="status.name"
            />
          </a-tag>
        </template>
      </template>
      <template v-else-if="count" #title>
        {{ title }}
        <a-badge
          :count="'Total user: ' + count"
          :overflow-count="999999"
          :class="type == 'default' ? null : 'count-' + type"
        />
        <span class="title pl-3">{{ description }}</span>
      </template>
      <template v-else #title>
        {{ title }}
      </template>
    </a-page-header>
  </div>
</template>

<script>
export default {
  name: 'TitleBreadcrumbs',
  props: {
    title: String,
    description: String,
    status: Object,
    databreadcrumbs: Array,
    count: String,
    lastupdated: String,
    titlesavelist: String,
    parent: Object, 
    current: String, 
    breadcrumbs: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      type: 'list',
      statusobj: [
        {
          id: 1,
          name: 'Creating',
          color: 'blue',
        },
        {
          id: 2,
          name: 'Cancelled',
          color: 'red',
        },
        {
          id: 3,
          name: 'Publish to market',
          color: 'green',
        },
        {
          id: 4,
          name: 'Expired',
          color: 'default',
        },
        {
          id: 5,
          name: 'Waiting',
          color: 'yellow',
        },
      ],
    }
  },
}
</script>
<style lang="scss" scoped>
.lastupdate {
  color: #969696;
  font-size: 16px;
  font-weight: normal;
}
.title {
  font-size: 0.8571428571rem;
  font-weight: normal;
  color: #969696;
}
.titlesavelist {
  color: #f9a601;
  font-size: 16px;
}
</style>

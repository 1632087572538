import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  plugins: [createPersistedState()],
  state: {
    loading: false,
    collapsed: false,
    themecolor: 'light',
    isSearch: false,
    localecode: 'en',
    menu: [],
    menuProduct: [],
    menuHelpCenter: {},
  },
  mutations: {
    setLoading(state, val) {
      state.loading = val
    },
    setCollapsed(state, value) {
      state.collapsed = value
    },
    setIsSearch(state, value) {
      state.isSearch = value
    },
    setLocalecode(state, value) {
      state.localecode = value
    },
    setMenu(state, value) {
      state.menu = value
    },
    setMenuProduct(state, value) {
      state.menuProduct = value
    },
    setMenuHelpCenter(state, value) {
      state.menuHelpCenter = value
    },
  },
  actions: {},
  modules: {},
})
